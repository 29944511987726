import { IRequestToSign, RequestSigningService } from '@inconvo/inconvo-aws-signed-requests';
import {
    IChannelsApiClient,
    IChannelsRequest,
    IDiscoveryMenuRequest,
    IFeaturedChannelsRequest,
    ISubscribeChannelRequest,
} from '@/api/interfaces/channelsApiClient.interface';
import {
    IClientRequest,
    IUpdateClientAfterRegistrationRequest,
    IUpdateClientRequest,
    IUpdateClientResponse,
    IUpdateClientSubscriberIdRequest,
} from '@/api/interfaces/clientApiClient.interface';
import {
    IStoreSubscriberRequest,
    IStoreSubscriberResponse,
    IUpdateSubscribedUserRequest,
    IUserApiClient,
} from '@/api/interfaces/userApiClient.interface';
import {
    IValidateRecontactTokenDto,
    IValidateRecontactTokenResponse,
} from '@/api/interfaces/recontactTokenApiClient.interface';
import { auth as AuthService } from '@/auth';
import config from '@/config';
import {
    IChannel,
    IClient,
    IDiscoveryItem,
    IGetEmailSubscriptionsResponse,
    IPaging,
    IRegisterUserPayload,
    IRegisterUserResponse,
    IRegisterYougovUserPayload,
    ISubscription,
    IOptInOutRequest,
} from '@/interfaces';
import {
    IAuthChallengeResponse,
    ISendAuthChallengeAnswerPayload,
    IVerifyUserPayload,
    IVerifyUserResponse,
} from '@/interfaces/auth.interface';
import { ICategory } from '@/interfaces/channel.interface';
import { IContentItem } from '@/interfaces/content-item.interface';
import { IChannelDto, IConvoDto, IConvoMetadataDto } from '@/interfaces/dtos.interface';
import { IMessage } from '@/interfaces/message.interface';
import RequestService from '@/services/request.service';
import RequestServiceV2 from '@/services/v2/request.service';
import { ICredentials } from '@aws-amplify/core';
import { browser } from '@inconvo/utils';
import axios, { AxiosInstance, AxiosRequestConfig } from 'axios';
import { IVerifyRecontactDto, IVerifyRecontactRequest } from '@/interfaces/verify-recontact.interface';
import { IContentApiClient, IGetConvosRequest } from '../interfaces/contentApiClient.interface';
import {
    IValidateTenantRequestDto,
    IValidateTenantResponseDto,
} from '../interfaces/tenantValidationApiClient.interface';

let API;
if (process.server || browser.storage.isSupported()) {
    API = require('@aws-amplify/api').default;
}

class ApiClient implements IUserApiClient, IChannelsApiClient, IContentApiClient {
    signingService: RequestSigningService;
    axiosClient: AxiosInstance;
    constructor() {
        this.axiosClient = axios.create({
            baseURL: config.messagingApiBaseUrl,
        });
        this.signingService = new RequestSigningService(
            config.identityPoolId,
            config.region,
            config.storageKeys.identityId,
            config.storageKeys.credentials,
            config.messagingApiBaseUrl,
        );
    }

    // UNSIGNED
    public async updateClient(data: IUpdateClientRequest): Promise<IUpdateClientResponse> {
        const response = await RequestService.put('v2/client', data);
        return (response as unknown) as Promise<IUpdateClientResponse>;
    }

    public async updateClientSubscriberId(data: IUpdateClientSubscriberIdRequest): Promise<any> {
        const url = `client/${data.clientId}/subscriber/${data.subscriberId}`;
        const response = await API.post('messaging', url, data);
        return (response as unknown) as Promise<any>;
    }

    public async getSubscribedChannels(): Promise<IPaging<IChannel>> {
        const response = await RequestService.get('v2/channels/subscribed', {
            headers: { 'tenant-app-key': config.tenantAppKey },
        } as AxiosRequestConfig);
        return (response as unknown) as Promise<IPaging<IChannel>>;
    }

    public async subscribeToChannel(data: ISubscribeChannelRequest): Promise<ISubscription> {
        const credentials: ICredentials = await AuthService.getCredentials();
        data.identityId = credentials.identityId;
        const response = await RequestService.post('v2/channels/subscribe', data);
        return (response as unknown) as Promise<ISubscription>;
    }

    public async unsubscribeFromChannel(data: ISubscribeChannelRequest): Promise<any> {
        const response = await RequestService.post('v2/channels/unsubscribe', data);
        return (response as unknown) as Promise<any>;
    }

    public async signOut(username: string): Promise<void> {
        await RequestService.post('user/signout', {
            username,
        });
    }

    // SIGNED
    public async updateClientAfterRegistration(data: IUpdateClientAfterRegistrationRequest): Promise<any> {
        const url = `client/${data.client_id}/registration`;
        const options = {
            response: false,
            body: data,
        };
        const response = await API.post('messaging', url, options);
        return (response as unknown) as Promise<any>;
    }

    public async validateRecontactToken(data: IValidateRecontactTokenDto): Promise<IValidateRecontactTokenResponse> {
        const options = {
            response: false,
            body: data,
        };
        const response = await API.post('messaging', 'user/validate-recontact-token', options);
        return (response as unknown) as Promise<IValidateRecontactTokenResponse>;
    }

    public async validateTenant(data: IValidateTenantRequestDto): Promise<IValidateTenantResponseDto> {
        const request: IRequestToSign = {
            method: 'POST',
            path: 'v3/tenants/validate',
            body: {
                ...data,
            },
        };
        const { url, headers } = await this.signingService.signRequest(request);
        const response = await RequestService.post(url, request.body, { headers });

        return (response as unknown) as Promise<IValidateTenantResponseDto>;
    }

    public async verifyRecontactId(data: IVerifyRecontactRequest): Promise<IVerifyRecontactDto> {
        const config = {
            headers: {
                'Content-Type': 'application/json',
                'X-API-KEY': process.env.AUTH_API_KEY,
            },
        };
        const response = await RequestServiceV2.post(`user/verify-recontact`, data, config);
        return (response as unknown) as Promise<IVerifyRecontactDto>;
    }

    public async registerClient(
        data: IClientRequest = {
            clientType: 'Web',
            applicationCode: config.applicationCode,
        },
    ): Promise<IClient> {
        if (API) {
            const options = {
                response: false,
                body: data,
            };

            await AuthService.refreshCredentials();

            const response = await API.post('messaging', 'client', options);
            return (response as unknown) as Promise<IClient>;
        }
        return {} as IClient;
    }

    public async getCategories(): Promise<ICategory[]> {
        const response = await API.get('messaging', 'channels/categories', {});
        return (response as unknown) as Promise<ICategory[]>;
    }

    public async getChannels(data: IChannelsRequest | IFeaturedChannelsRequest): Promise<IPaging<IChannelDto>> {
        const options = {
            response: false,
            queryStringParameters: data,
            headers: {
                'tenant-app-key': config.tenantAppKey,
            },
        };

        await AuthService.refreshCredentials();

        const response = await API.get('messaging', 'channels', options);
        return (response as unknown) as Promise<IPaging<IChannelDto>>;
    }

    public async getChannel(slug: string): Promise<IChannel> {
        const response = await this.axiosClient.get(`channels/${slug}`, {});

        return (response?.data as unknown) as Promise<IChannel>;
    }

    public async getMessageById(messageId: string): Promise<IMessage> {
        await AuthService.refreshCredentials();

        const response = await API.get('messaging', `content/message/${messageId}`, {});
        return (response as unknown) as Promise<IMessage>;
    }

    public async getConvos(data: IGetConvosRequest): Promise<IPaging<IConvoDto>> {
        const options = {
            response: false,
            params: data,
            headers: {
                'tenant-app-key': config.tenantAppKey,
            },
        };

        const response = await this.axiosClient.get(`content/convos`, options);

        return (response?.data as unknown) as Promise<IPaging<IConvoDto>>;
    }

    public async getConvoMetadata(convoId: string): Promise<IConvoMetadataDto> {
        const response = await this.axiosClient.get(`content/convos/${convoId}/metadata`, {});
        return (response?.data as unknown) as Promise<IConvoMetadataDto>;
    }

    public async subscribeToChannelAsGuest(data: ISubscribeChannelRequest): Promise<ISubscription> {
        const options = {
            response: false,
            body: data,
        };

        await AuthService.refreshCredentials();

        const response = await API.post('messaging', 'channels/subscribe', options);
        return (response as unknown) as Promise<ISubscription>;
    }

    public async getContentDiscovery(data: IDiscoveryMenuRequest): Promise<IDiscoveryItem[]> {
        const options = {
            response: false,
            queryStringParameters: data,
        };

        const response = await API.get('messaging', 'content/discovery-menu', options);
        return (response as unknown) as Promise<IDiscoveryItem[]>;
    }

    public async storeSubscriber(data: IStoreSubscriberRequest): Promise<IStoreSubscriberResponse> {
        try {
            const options = {
                response: false,
                body: data,
            };
            const response = await API.post('messaging', 'subscribers', options);
            return (response as unknown) as Promise<IStoreSubscriberResponse>;
        } catch (error) {
            throw new Error('Could not store subscriber');
        }
    }

    public async optOutUser(payload: IOptInOutRequest): Promise<void> {
        const params: IUpdateSubscribedUserRequest = {
            application_code: config.applicationCode,
            user_id: payload.userId,
            email: payload.email,
            subscription: payload.subscription,
            all: payload.all,
        };
        const request: IRequestToSign = {
            method: 'POST',
            path: 'subscribers/unsubscribe',
            body: params,
        };
        const { url, headers } = await this.signingService.signRequest(request);
        await RequestService.post(url, request.body, { headers });
    }

    public async optInUser(payload: IOptInOutRequest): Promise<void> {
        const params: IUpdateSubscribedUserRequest = {
            application_code: config.applicationCode,
            user_id: payload.userId,
            email: payload.email,
            subscription: payload.subscription,
            all: payload.all,
        };
        const options = {
            response: false,
            body: params,
        };
        await API.post('messaging', 'subscribers/subscribe', options);
    }

    public async registerUser(payload: IRegisterUserPayload): Promise<IRegisterUserResponse> {
        const options = {
            response: false,
            body: payload,
        };
        const response = await API.post('messaging', 'user/register', options);
        return (response as unknown) as Promise<IRegisterUserResponse>;
    }

    public async registerYougovUser(payload: IRegisterYougovUserPayload): Promise<IAuthChallengeResponse> {
        const options = {
            response: false,
            body: payload,
        };
        const response = await API.post('messaging', 'user/register-yougov', options);
        return (response as unknown) as Promise<IAuthChallengeResponse>;
    }

    public async registerUserWithRecaptcha(payload: IRegisterUserPayload): Promise<IRegisterUserResponse> {
        const config = {
            headers: {
                'x-recaptcha-token': payload.recaptchaToken,
            },
        };

        const response = await RequestServiceV2.post('user/register_recaptcha', payload, config);
        return (response as unknown) as Promise<IRegisterUserResponse>;
    }

    public async sendAuthChallengeAnswer(payload: ISendAuthChallengeAnswerPayload): Promise<IAuthChallengeResponse> {
        const options = {
            response: false,
            body: payload,
        };
        const response = await API.post('messaging', 'user/auth-challenge', options);
        return (response as unknown) as Promise<IAuthChallengeResponse>;
    }

    public async verifyUser(payload: IVerifyUserPayload): Promise<IVerifyUserResponse> {
        const options = {
            response: false,
            body: payload,
        };
        const response = await API.post('messaging', 'user/verify', options);
        return (response as unknown) as Promise<IVerifyUserResponse>;
    }

    public async getContentItem(contentItemId: number): Promise<IContentItem> {
        await AuthService.refreshCredentials();

        const response = await API.get('messaging', `content/content-item/${contentItemId}`, {});
        return (response as unknown) as Promise<IContentItem>;
    }

    public async getEmailSubscriptions(recontactId: string): Promise<IGetEmailSubscriptionsResponse> {
        const request: IRequestToSign = {
            method: 'GET',
            path: 'subscribers/subscriptions',
            queryParams: {
                recontact_id: recontactId,
            },
        };
        const { url, headers } = await this.signingService.signRequest(request);
        const response = await RequestService.get(url, { headers });

        return (response as unknown) as Promise<IGetEmailSubscriptionsResponse>;
    }
}

export default new ApiClient();
